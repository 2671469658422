<!--包烨明-公众号跳转-借款页面-->
<template>
	<div>
		<div style="margin-top: 5%">
			<el-button type="primary" style="width: 100%" @click="goLoanMain">新增借款信息</el-button>
		</div>

		<div>
			<div v-for="(item, index) in loan_messages" :key="index" style="margin-top: 5%;padding: 5%;background: #0b89e5"
			@click="goAddrepayment(item)">
				<div style="display: grid;grid-template-columns: 1fr 1fr;width: 100%">
					<div>
						借款人(企业):{{ item.borrower }}
					</div>
					<div>
						借款金额:{{ item.loanAmount }}   (利率:{{ item.interestRate }})
					</div>
				</div>
				<el-divider />
				<div style="display: grid;grid-template-columns: 1fr 1fr;width: 100%">
					<div>
						借款日期:{{ item.loanDate.slice(0, 10) }}
					</div>
					<div>
						约定偿还日期:{{ item.reimburseDate.slice(0, 10) }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";

export default {
	name: "Loan_index",
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			loan_messages: [],
			user_id: ''
		}
	},
	created() {
		this.user_id = this.$route.params.user_id
		this.getAllLoanMessage()
	},
	methods: {
		goLoanMain(){
			const time = Date.parse(new Date());
			this.$router.push({
				name: 'Loan_main',
				params: {
					key: time,
					user_id: this.$route.params.user_id
				}
			});
		},
		getAllLoanMessage(){
			this.axios.get('/loanMain/getAllLoanMessage', (response) => {
				this.loan_messages = response.obj
			})
		},
		goAddrepayment(item){
			this.$router.push({
				name: 'Loan_repayment',
				params: {
					key: item.id,
					user_id: this.$route.params.user_id
				}
			});
		}
	}
}
</script>

<style>

</style>